<template>
  <div class="wrapper"> 
    <div class="main-panel" style="overflow: hidden;">
      <top-navbar></top-navbar>
      <div class="container">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <dashboard-content></dashboard-content>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
      <!-- <content-footer  class="footer-container typefooter-1"></content-footer> -->
    </div>
  </div>
</template>
<script>
import TopNavbar from './BuyerDashboard/TopNavbarBuyer.vue'
import DashboardContent from './DeliverDashboard/Content.vue'
// import ContentFooter from './DeliverDashboard/ContentFooter.vue'
export default {
  components: {
    TopNavbar,
    DashboardContent
    // ContentFooter
  },
  methods: {
  }
}
</script>
