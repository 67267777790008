<template>
<div>
   <div id="wrapper" class="wrapper-fluid banners-effect-3">
    <header id="header" class=" typeheader-1">
        <div class="header-middle" style="margin-top: 0px;background-color: white;
            box-shadow: 0px 0px 5px -1px rgba(0,0,0,1);">
            <div class="container" style="min-height: 92px;width: 100%;max-width: initial;">
                <div class="row">
                    <!-- <div class="navbar-logo col-lg-2 col-md-3 col-sm-4 col-xs-9">
                        <div class="logo delivary-logo">
                            <a @click="handleByOnSlt">
                                <img class="img-logo" src="https://res.cloudinary.com/persystance-network/image/upload/v1589092077/slt/portal_user/1589092077321.png" title="Your Store" alt="Your Store" /></a>
                        </div>
                    </div> -->
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 newlogo-block">
                        <div id="merchhdrlogo">
                            <a @click="handleByOnSlt()">
                                <img  src="https://res.cloudinary.com/persystance-network/image/upload/v1593918873/slt/portal_user/1593918873513.png" alt="mdb logo">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="header-bottom hidden-compact" style="margin-top: -22px;">
            <div class="container">
                <div class="row">   
                    <div class="bottom3">                        
                        <div class="telephone hidden-xs hidden-sm hidden-md">
                            <ul class="signin-link blank delpart-links"> 
                                <li v-if="this.$store.state.deliver_accessToken !== null"><a @click="pushdeliverProfile"><i class="fa fa-user"></i>Profile</a></li> 
                            </ul>
                        </div>  
                        <div class="signin-w hidden-md hidden-sm hidden-xs" v-if="this.$store.state.deliver_accessToken === null">
                            <ul class="signin-link blank">                            
                                <li class="log login"><i class="fa fa-lock"></i> <a class="link-lg" @click="login"> Login </a> or <a @click="register">Register</a></li>                                
                            </ul>                       
                        </div>   
                        <div class="signin-w hidden-md hidden-sm hidden-xs" v-if="this.$store.state.deliver_accessToken != null">
                            <ul class="signin-link blank">                            
                                <li class="log login"><i class="fa fa-unlock"></i> <a class="link-lg" @click="logout"> Log Out</a></li>                                
                            </ul>                       
                        </div>                  
                    </div>            
                </div>
            </div>
        </div> -->
    </header>
    </div>
</div>
</template>

<script>
export default {
  name: 'TopHeader',
  data(){
      return{
          loggedin:false
      }
  },
  methods:{
      handleByOnSlt: function() {
        this.$router.push('/').catch(err => {});
      },
      handleByOnSltlanding: function() {
        this.$router.push('deliver-landing').catch(err => {});
      },
      handledeliverProfile: function() {
          this.$router.push('profile-configure').catch(err => {});
      },
      login(){
          this.$router.push('/deliver-login').catch(err => {});
      },
      logout(){
          if ('order_dispatch_id' in localStorage) {
            localStorage.removeItem("order_dispatch_id");
          }
          if ('deliver_order_group_id' in localStorage) {
            localStorage.removeItem("deliver_order_group_id");
          }
          this.$store.dispatch("logoutdeliver");
        //   this.$router.push('/').catch(err => {});
          this.$router.push('/deliver-login').catch(err => {});
      },
      register(){
          this.$router.push('/deliver-register').catch(err => {});
      },
      pushdeliverProfile() {
        this.$router.push('/deliver-profile').catch(err => {});                 
      },
      deliverLanding(){
        this.$router.push('/deliver-profile').catch(err => {});
      },
  }
}
</script>
<style scoped>
@media screen and (min-width: 1575px){
    .newlogo-block{
        width: 20%;
    }
    .header-portal-title{
        width: 80%;
    }
     #merchhdrlogo img{
         width: 47.5%;

     }
}
.newlogo-block{
    overflow:hidden;
    padding-left: 0px;
}
#merchhdrlogo{
    width: 100%;
    height: 92px;
    margin: 0 0 0 -20px;
    -webkit-transform: skew(20deg);
       -moz-transform: skew(20deg);
         -o-transform: skew(20deg);
    background: #de1a22;
    overflow:hidden;
    position:relative;
    outline: 1px solid red;
}
#merchhdrlogo img{
  display: block;
  width: 45%;
  margin: 14px auto 0 auto;
   -webkit-transform: skew(-20deg);
       -moz-transform: skew(-20deg);
         -o-transform: skew(-20deg);

}
@media screen and (max-width: 1024px){
    #merchhdrlogo img{
        width: 55%;
    }    

}
@media (max-width: 1199px) and (min-width: 992px) {
    /* header.typeheader-1 .logo {
        margin-top: 0px !important;
        margin-left: -15px;
        max-width: 250px;
    } */
}
@media screen and (max-width: 875px){
    #merchhdrlogo img{
        margin: 17px auto 0 auto;
    }    
}
@media screen and (max-width: 575px){
    #merchhdrlogo img{
        width: 55%;
    }
    .newlogo-block{
        width: 45%;
    }
}
@media (max-width: 991px) and (min-width: 768px) {
    /* header.typeheader-1 .logo {
        text-align: center;
        margin-left: -15px;
        max-width: 250px;
    } */
    .newlogo-block{
        width: 40%;
    }
    #merchhdrlogo img{
        width: 50%;
    }
}
@media (max-width: 767px) {
    /* header.typeheader-1 .logo {
        text-align: center;
        margin-left: -15px;
        max-width: 250px;
    } */
}
@media screen and (max-width: 425px){
    #merchhdrlogo img{
        width: 55%;
        margin: 20px auto 0 auto;
    }

}
@media screen and (max-width: 350px){
    .newlogo-block{
        width: 100%;
        padding-right: 0px;
    }
    .header-portal-title{
        width: 100%;
        text-align: center;
        padding-bottom: 15px;
    }
    #merchhdrlogo{
     margin-left: 0px;   
    -webkit-transform: none;
       -moz-transform: none;
         -o-transform: none;

}
#merchhdrlogo img{
  display: block;
  width: 40%;
  margin: 17px auto 0 auto;
-webkit-transform: none;
-moz-transform: none;
-o-transform: none;

}
} 
@media screen and (min-width: 1575px){
    .newlogo-block{
        width: 20%;
    }
    .header-portal-title{
        width: 80%;
    }
     #merchhdrlogo img{
         width: 47.5%;

     }
}
@media screen and (min-width: 1775px){
    #merchhdrlogo img{
        margin: 10px auto 0 auto;
     }

}
@media screen and (min-width: 1920px){
    #merchhdrlogo img{
        width: 42%;
        margin: 12px auto 0 auto;
     }

}
@media screen and (min-width: 2000px){
    #merchhdrlogo img{
        width: 35%;
        margin: 15px auto 0 auto;
     }

}
@media screen and (min-width: 2300px){
    #merchhdrlogo img{
        width: 32%;
        margin: 16px auto 0 auto;
     }
}
/* .delivary-logo {
    padding-top: 15px;
} */
.delpart-links li a:hover{
    color: #ffd338;
}
.fa-unlock:hover{
    color: #ffd338;
}
.buyon-text a:hover{
    color: #ffd338;
}
/* .img-logo{
    min-height: 92px;
    max-width: 328px;
    margin-left: -37px;
    margin-top: -15px;
} */
/* .logo-bg img{
    width: 100%;
} */
</style>

